// Fonts
@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,600");
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Select2
@import "node_modules/select2/src/scss/core";

// Ioniocns
@import "ionicons/scss/ionicons.scss";
@import "mnml-spinner.min.css";

@import "mixins.scss";
@import "general.scss";
@import "header.scss";
@import "timeline.scss";
@import "forms.scss";
@import "participants-list.scss";
@import "groups.scss";
@import "landing.scss";
@import "schedules.scss";
@import "new-participant.scss";
@import "confirmation.scss";
@import "profile-change.scss";

html{
    overflow-y:scroll;
}

img.destination{
    position: absolute;
    left: 0;
    width: 200px;
    top: 20px;
}

img.youthmusic{
    position: absolute;
    right: 0;
    width: 130px;
    top: 20px;
}

.floaters{
    height:120px;
}

ul.participants-list{
    list-style: none;
    padding: 0;

    label{
        font-weight:normal;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-size:14px;
        cursor: pointer;
        color: darken($site-white, 20%);

        &:hover{
            color:#fff;
        }

        span{
            vertical-align: top;
        }
    }
}

.big-icon{
    font-size:100px;
}

.icon-failed{
    color: $site-red;
}

.icon-success{
    color: #66d2a0;
}

.currency{
    span{
        line-height: 0px;
    }
}

// Admin stuff
.dashboard-button{
    width: 100%;
    height: 100px;
    border:1px solid $site-white;
    background-color: #fff;
    padding-top: 12px;
    margin-bottom: 12px;
    color: #ababab;
    @include transition(all, 0.3s,ease-in-out,0);

    .icon{
        font-size: 30px;
    }

    &.active{
        border-bottom: 3px solid $site-white;
        background-color: #fff;;
        border: 1px solid #ddd;
        color:#333;
    }

    &:hover{
        border:1px solid #ddd;
    }

    p{
        font-size: 13px;
        text-transform: uppercase;
        margin-top: 4px;
    }
}

// Quill Editor
.quill-editor{
    background-color: #fff;
    color: #333;

    .quill-container{
        color:#333;
    }
}

.preview{
    word-wrap: break-word;
    min-height: 300px;
    border: 1px solid #333;
    padding: 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .4s ease;
  // transform: scale(1);
}
.fade-enter, .fade-leave-active {
  opacity: 0;
  // transform: scale(0);
}


// .slide-enter, .slide-leave-active {
//     transform: scaleY(0);
// }
//
// .slide-enter-active, .slide-leave-active {
//     opacity: 0;
//     -webkit-transform: translate(30px, 0);
//     transform: translate(30px, 0);
// }
// .slide-enter, .slide-leave-active {
//     opacity: 0;
//     -webkit-transform: translate(-30px, 0);
//     transform: translate(-30px, 0);
// }

.child-view {
  position: absolute;
  transition: all .5s cubic-bezier(.55,0,.1,1);
}
.slide-left-enter, .slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active, .slide-right-enter {

}

button.dropdown-toggle{
	font-size: 14px;
	padding: 5px 25px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 3px;
	color:$body-bg;
    min-width: 190px;
}

.dropdown-menu{
	right: 0;
    left: inherit;
    top: 100%;
    border: 1px solid #ddd;
	color:$body-bg;
    border-radius: 0px 0px 3px 3px;
    border-top: none;
    min-width: 190px;
    margin:0;
}

.sagepay-powered{
	position: absolute;
	right: 30px;
	bottom: -35px;
}

.payment-submit{
    margin-top: 20px !important;
}

.timeline-marker:hover{
	z-index:5;
}

#pay {
    margin: 50px auto;
}

.banner-image{
    height: 200px;
    background-size: cover;
    background-position-y: 50%;
    border-radius: 4px 4px 0px 0px;
    padding: 30px;
    text-align: right;

    .text-right{
        z-index: 5;
        position: relative;
    }

    h2{
        font-weight: bold;
        letter-spacing: 6px;
        text-transform: uppercase;
        font-size: 30px;
    }

    p{
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 2px;
    }

    a{
        color: #fcea68;
    }
}

.colour-overlay:after{
    position: absolute;
    content: "";
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: 200px;
    opacity: 0.5;
    background-color: $body-bg;
    margin-right: 15px;
    border-radius: 4px 4px 0px 0px;
}

.alert-danger{
    font-size: 14px;
}

span.help-block{
    margin-top: 13px;
    font-size: 14px;
    text-align: center;
}

fieldset[class*="has-error"] + fieldset {
    /* margin-top: 20px; */
    margin-top:0px;
}

#forgot-link{
    margin-top: 14px;
    display: block;
    font-size: 14px;
}

#stay-logged-in{
    font-size: 14px;
    font-weight: normal;
    margin-left: 11px;
    vertical-align: middle;
}

#copyright{
    margin-top:20px;
}

ul.page-links{
    text-align: center;
    list-style: none;
    font-size: 14px;
    padding: 0;
    margin-top: 25px;

    li{
        display: inline;
        padding: 5px 10px 5px 10px;
    }

    li + li{
        border-left:1px solid #333;
    }
}

.body-yellow{
    ul.page-links{
        color: $body-bg;
    }
}

.page-content-wrapper{
    padding: 50px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: #fff;
}

li.select2-results__option {
    color: #808080;
}

.marker span{
    color:$site-white !important;
}

form.search-form input[type="text"] {
    color: #333;
}

@media screen and ( min-width: $screen-md ) {
    .col-block-5{
        width:20%;
        display: inline-block;
    }
}


// TEMPPPPP TODO REMOVE THIS
// .bounce-enter-active {
//   animation: bounce-in .5s;
// }
// .bounce-leave-active {
//   animation: bounce-in .5s reverse;
// }
// @keyframes bounce-in {
//   0% {
//     transform: scale(0);
//   }
//   50% {
//     transform: scale(1.5);
//   }
//   100% {
//     transform: scale(1);
//   }
// }
.flip-list-move {
  transition: all 1s;
}
