body {
    font-family: "Open Sans",sans-serif;
    font-size: 17px;
    line-height: 19px;
    letter-spacing: 1px;
    color: $business-white;
    background-color: $business-white;

    word-break: normal;
    &.body-white {
        background-color: #fff;
        p, span, a, h1, h2 {
            color: $business-black;
        }
        .confirmation-wrapper {
            // border-color: #ddd;
            font-family: "Work Sans";
            padding: 20px;
            padding-top: 60px;
        }
    }
    &.body-yellow {
        background-color: $business-white;
        p, span, a, h1, h2 {
            color: $body-bg;
        }
    }

}
// Fonts
h1 {
    font-size: 40px;
    line-height: 42px;
    letter-spacing: 1px;
    color: #fff;
    font-weight: 300;
    font-family: "Work Sans";
}
// h2 {
//     font-size: 17px;
//     line-height: 19px;
//     color: #fff;
//     font-weight: 400;
// }
.section-welcome-message {
    div[class*="col-xs"] + div[class*="col-xs"] {
        margin-top: 30px;
    }
}
h1.welcome-message {
    margin: 0;
}
.text-bold {
    font-weight: 600;
}
.text-small{
    font-size:14px;
}
.text-uppercase {
    text-transform: uppercase;
}
// text colours
.text-red {
    color: $site-red;
}
.text-blue {
    color: $site-blue;
}
.text-black {
    color: $body-bg;
}
.text-white{
    color:$site-white;
}
// Backgrounds
.bg-pink {
    background-color: $site-pink;
}
.bg-yellow {
    background-color: $site-yellow;
}
.bg-red {
    background-color: $site-red;
}
.bg-purple {
    background-color: $site-purple;
}
.bg-white {
    background-color: $site-white;
}
.bg-grey {
    background-color: $site-grey;
}
.bg-green {
    background-color: $site-green;
}
.bg-blue {
    background-color: $site-blue;
}
.bg-black {
    background-color: $body-bg;
}
.section {
    padding: 20px 0;
}

.button-link, button:not(.dropdown-toggle, .dashboard-button, .table-button, [class^="ql-"]){
       // Base styles f1or buttons (in this case the default is the yellow button due to its common use in the design)
       display: inline-block;
       text-align: center;
       text-transform: uppercase;
       padding: 15px 25px 13px;
       font-weight: 600;
       font-size: 12px;
       line-height: 18px;
       letter-spacing: 2px;
    //    border-radius: 2px;
       background-color: transparent;
       color: $site-white;
       border: none;
       width: 100%;
       position: relative;
       z-index: 2;
       margin: 5px 0;
       @include transition(all,0.5s,ease,0s);
       border: 1px solid #ddd;
        color: #636363;
    //    &:hover, &:focus {
    //        color: $body-bg;
    //        &:before {
    //            width: calc(100% + 10px);
    //            height: calc(100% + 10px);
    //        }
    //        &:after {
    //            opacity: 0.3;
    //        }
    //    }
       &.scheme-plain {
           color: $site-white;
           font-size: 22px;
           line-height: 24px;
           font-weight: 300;
           letter-spacing: normal;
           padding: 15px 10px;
           background-color: $body-bg;
           &:after, &:before {
               border-color: $site-white;
           }
           &:hover, &:focus {
               background-color: $site-white;
               color: $body-bg;
           }
       }
       &.scheme-red {
           background-color: $site-red;
           color: #fff;
           &:after, &:before {
               border-color: $site-red;
           }
           &:hover, &:focus {
               background-color: $site-red;
               color: #fff;
           }
       }
       &.scheme-green {
           background-color: $business-green;
           color: $site-white;
           span{
               color:$site-white;
           }
           &:after, &:before {
               border-color: $business-green;
           }
           &:hover, &:focus {
               background-color: $business-green;
               color: $site-white;
           }
       }
       // General button pseudo
    //    &:before, &:after {
    //        content: "";
    //        position: absolute;
    //        top: 0;
    //        left: 0;
    //        display: block;
    //        width: 100%;
    //        height: 100%;
    //        border: 1px solid $site-yellow;
    //        border-radius: 2px;
    //        @include transition(all,0.3s,ease,0s);
    //        @include translate(50%,50%,-50%,-50%);
    //    }
       &:before {
           opacity: 1;
           z-index: 1;
       }
       &:after {
           opacity: 0;
       }
       // General button hover
       &:hover, &:focus {
           text-decoration: none;
       }
       &:focus {
           box-shadow: none;
           outline: none;
       }
       &:before, &:after {
           display: none;
       }
       &:hover, &:focus {
           color:#333;
        //    background-color: darken($business-blue, 10%);
       }
       &.scheme-plain {
           &:hover, &:focus {
            //    background-color: darken($business-blue, 10%);
           }
       }
       &.scheme-red {
           &:hover, &:focus {
               color:#fff;
               background-color: darken($site-red, 10%);
           }
       }
       &.scheme-green {
           &:hover, &:focus {
               background-color: darken($business-green, 10%);
           }
       }
   }





.search-table {
    ul {
        list-style: none;
        padding: 0;
        li {
            position: relative;
            font-size: 15px;
            padding: 5px 0;
            @include transition(all,0.3s,ease,0s);
            & + li {
                margin-top: 5px;
            }
            &.selected {
                font-weight: 600;
                padding-left: 15px;
                &:before {
                    opacity: 1;
                }
            }
            &:not(.selected):hover {
                cursor: pointer;
                cursor: -webkit-pointer;
                &:after {
                    width: 100%;
                }
            }
            &:before {
                content: "";
                width: 5px;
                height: 5px;
                display: block;
                border-radius: 100%;
                position: absolute;
                left: 0;
                opacity: 0;
                @include translateY(50%,-50%);
                @include transition(all,0.5s,ease,0s);
            }
            &:after {
                content: "";
                display: block;
                width: 0px;
                height: 1px;
                position: absolute;
                left: 0;
                bottom: 0;
                @include transition(all,0.7s,ease,0s);
            }
        }
        li {
            &.selected {
                &:before {
                    background-color: $site-yellow;
                }
            }
            &:after {
                background-color: $site-yellow;
            }
        }
    }
}
form.search-form {
    position: relative;
    margin-bottom: 10px;
    input {
        width: 100%;
        border: none;
        background-color: transparent;
        padding: 5px 0;
        font-size: 15px;
        text-transform: uppercase;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $site-grey;
    }
}
@include placeholder {
    font-size: 15px;
}

// Clear border for WYSIWYG
.ql-toolbar button:before, .ql-toolbar button:after {
    display:none;
}

.ql-editor{
    h1,h2,h3,h4{
        color:$body-bg;
    }
}

// Media Queries
@media screen and ( min-width: $screen-sm ) {
    h1 {
        font-size: 60px;
        line-height: 62px;
    }
    .section-welcome-message {
        div[class*="col-xs"] + div[class*="col-xs"] {
            margin-top: 0;
        }
    }
}
@media screen and ( min-width: $screen-sm ) {
    .button-link, button {
        margin: 0;
    }
}
