.confirmation-wrapper {
    border-radius: 2px;
    border: 1px solid $site-white;
    padding: 30px 15px;
    background-color: #fff;
    color: $body-bg;
    max-width: 768px;
    margin: 0 auto;
    text-align: center;

    .h1 {
        margin: 0;
        font-weight:300;
        letter-spacing: 2px;
        line-height: 2px;
        line-height: 60px;
    }

    .details{
        font-size:14px;
    }

    p.address{
        margin-left: 20px;
        font-size: 13px;
    }
}
.confirmation-buttons {
    margin-top: 40px;

    button{
        margin-top:20px;
    }

    button + button {
        margin-top: 25px;
    }
}

// Media Queries
@media screen and ( min-width: $screen-md ) {
    .confirmation-wrapper {
        padding: 80px 95px;
    }
}
