.section-participant-list {
    text-transform: uppercase;
    .options-row {
        button {
            margin-top: 20px;
        }
    }
    .transfer-table {
        margin-top: 20px;
        padding: 25px 15px;
        color: $body-bg;
        background-color: #fff;
        border: 1px solid #eee;
        ul {
            list-style: none;
            padding: 0;
            li {
                position: relative;
                font-size: 15px;
                padding: 5px 0;
                @include transition(all,0.3s,ease,0s);
                & + li {
                    margin-top: 5px;
                }
                &.selected {
                    font-weight: 600;
                    padding-left: 15px;
                    &:before {
                        opacity: 1;
                    }
                }
                &:not(.selected):hover {
                    cursor: pointer;
                    cursor: -webkit-pointer;
                    &:after {
                        width: 100%;
                    }
                }
                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    display: block;
                    border-radius: 100%;
                    position: absolute;
                    left: 0;
                    opacity: 0;
                    @include translateY(50%,-50%);
                    @include transition(all,0.5s,ease,0s);
                }
                &:after {
                    content: "";
                    display: block;
                    width: 0px;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    @include transition(all,0.7s,ease,0s);
                }
            }
            &.participants-from {
                li {
                    &.selected {
                        &:before {
                            background-color: $site-red;
                        }
                    }
                    &:after {
                        background-color: $site-red;
                    }
                }
            }
            &.participants-to {
                li {
                    &.selected {
                        &:before {
                            background-color: $site-blue;
                        }
                    }
                    &:after {
                        background-color: $site-blue;
                    }
                }
            }
        }
        .receipt {
            margin-top: 30px;
            .transfer-details {
                margin: 30px 0;
            }
        }
        .col-xs-12 + .col-xs-12 {
            margin-top: 20px;
        }
    }
}
.list-table-wrapper {
    overflow-x: auto;
    padding: 40px 0;
    color: $site-white;
}
.list-table {
    width: 100%;
    a {
        color: $site-white;
        &:hover, &:focus {
            text-decoration: none;
            color: $site-white;
        }
    }
    th, td {
        // min-width: 150px;
        padding: 12px 1px;
    }
    th {
        font-weight: 400;
        font-size: 15px;
        text-transform: none;
    }
    tr:first-of-type {
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
        padding-bottom: 20px;
    }
    tr.participant-row {
        border-bottom: 1px solid $site-grey;
        font-size: 15px;
        &.payments-overdue-alert {
            background-color: $site-red;
        }
        &.payments-overdue-warning {
            color: $body-bg;
            background-color: $site-yellow;
            a {
                color: $body-bg;
                &:hover, &:focus {
                    color: $body-bg;
                }
            }
        }
    }
    td {
        // padding-top: 30px;
    }
}


.good-table{
    font-size:14px;
    color:$site-white;
    max-width: 1400px;
    margin: 0 auto;
    border-radius: 3px;

    h2 {
        font-size: 17px;
        line-height: 19px;
        color: #fff;
        font-weight: 400;
    }

    input{
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 8px;
        font-weight: normal;
        font-size: 11px;
        width: 100%;
        color: #333;

        &::placeholder{
            font-size:11px;
        }
    }

    table{
        text-transform: none;

        tr.yellow{
            // background-color: $site-yellow;
            background-color: $business-warn;
            color:$body-bg;

        }
        tr.red{
            background-color: $site-red;
            color:#fff;
        }
    }

    thead{
        background-color: #fff;
    }

    table td{
        padding:10px !important;
    }
    .table-bordered{
        border:1px solid #eee ;
    }
}

.homepage{
    .good-table{
        color:  #24292e !important;
    }
    .good-table .table-bordered {
        border: 1px solid #ddd;
        color:#333 !important;
    }
    .table-footer[data-v-2741b381]{
        border: 1px solid #ddd;
        color: #24292e;
    }
    .table td, .table th{
        color: #24292e !important;
    }
    .table-header{
        h2{
            font-size: 18px;
            line-height: 18px;
            color: #24292e;
            padding-left: 12px;
        }
    }
    thead{
        border: 1px solid #ddd;
        color: #24292e;
        background-color:$business-white;
    }
}

td.actions{
    position: relative;
    border:none;
    width: 40px;
    cursor:pointer;

    i.more{
        position: absolute;
        color: $business-black;
        right: 0;
        top: 0;
        z-index: 3;
        cursor: pointer;
        padding: 10px 15px;
        background-color: $business-white;

        height: 100%;
        width: 100%;

        &.active{
            color:$business-white;
            // background-color: #fff;
            background-color: $business-black;
        }
    }

    .actions-list{
        width: 250px;
        position: absolute;
        top: 0;
        right: 38px;
        // background-color: $site-white;
        background-color: $business-black;
        border-radius: 3px;
        z-index: 20;


        ul{
            padding: 0;
            list-style: none;

            li{
                text-align: left;
                padding-top: 12px;
                padding-left: 20px;
                cursor: pointer;
                color:$business-white;

                i{
                    padding-right: 16px;
                }

                &:hover{
                    color:$site-blue;

                }
            }
        }
    }
}



th.line-numbers{
    color: #333;
}

// Media Queries
@media screen and ( min-width: $screen-md ) {
    .section-participant-list {
        .transfer-table {
            .receipt {
                margin-top: 0;
            }
            .col-xs-12 + .col-xs-12 {
                margin-top: 0;
            }
        }
    }
}
