.section-landing-welcome-message {
    padding-bottom: 40px;
    padding-top:140px;
    h1 {
        margin-bottom: 30px;
    }
    .text-right, .trip-to {
        // text-transform: uppercase;
        text-align: left;
    }
}
.landing-main-header  {
    padding-left: 21px;
}
.landing-main-wrapper {
    padding: 30px 0;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    border-color: $site-grey;
    background-color: #fff;
    border-radius: 2px;

    .section-landing-main & {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }

    &.scheduling-information{
        padding-left: 16px;
        padding-right: 16px
    }
}
.landing-further-wrapper{
    background-color: #fff;
    border-radius: 2px;
    border-bottom:1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-top: none;
    border-color: $site-grey;
}

.section-landing-further {
    margin-bottom: 50px;
}
.payment-gateway-window {
    // border: 1px solid $site-grey;
    // border-radius: 2px;
    padding: 50px 0;
	// background-color: #fff;
    p {
        margin-bottom: 0;
    }
}
.landing-timeline-wrapper {
    overflow-x: auto;
    padding: 30px 21px;
    .group-name {
        color: $body-bg;
        padding-left: 21px;
    }
    .timeline-marker {
        .date {
            color: $body-bg;
        }
    }
    .progress-row {
        .col-xs-12 {
            padding: 0;
        }
    }
    .progress, .progress-bar {
        border-radius: 0;
    }
    .timeline-wrapper .base-date {
        bottom: -12px;
        &.start-date {
            left: 10px;
        }
        &.end-date {
            right: 10px;
        }
    }
}
.landing-main-button {
    margin-top: 60px;
    button {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
.landing-table-wrapper {
    overflow-x: auto;
    padding: 20px 6px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    .list-table {
        th, td {
            text-transform: uppercase;
            font-size: 18px;
        }
        tr {
            border: none;
        }
    }
}

// Media Queries
@media screen and ( min-width: $screen-sm ) {
    .section-landing-welcome-message {
        .text-right {
            text-align: right;
        }
    }
}
@media screen and ( min-width: $screen-md ) {
    .section-landing-welcome-message {
        .text-right {
            text-align: right;
        }
    }
    .landing-main-header  {
        padding-left: 0;
    }
    .landing-main-wrapper {
        padding: 30px 21px;
    }
    .landing-timeline-wrapper {
        overflow-x: visible;
        padding: 0;
    }
}
