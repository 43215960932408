.section-profile-change {
    .col-md-offset-2 + .col-xs-12 {
        margin-top: 30px;
    }
}
.billing-address-row {
    margin-top: 40px;
}

// Media Queries
@media screen and ( min-width: $screen-sm ) {
    .section-profile-change {
        .col-md-offset-2 + .col-xs-12 {
            margin-top: 0;
        }
    }
}