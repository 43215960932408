@mixin border-radius( $radius ) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}
@mixin transition( $effect, $time, $easing, $delay ) {
    -webkit-transition: $effect $time $easing;
    -moz-transition: $effect $time $easing;
    -ms-transition: $effect $time $easing;
    transition: $effect $time $easing;
    -webkit-transition-delay: $delay;
    -moz-transition-delay: $delay;
    -ms-transition-delay: $delay;
    transition-delay: $delay;
}
@mixin translateX($x-pos, $x-pos-change) {
    left: $x-pos;
    -webkit-transform: translateX($x-pos-change);
    -moz-transform: translateX($x-pos-change);
    -ms-transform: translateX($x-pos-change);
    -o-transform: translateX($x-pos-change);
    transform: translateX($x-pos-change);
}
@mixin translateY($y-pos, $y-pos-change) {
    top: $y-pos;
    -webkit-transform: translateY($y-pos-change);
    -moz-transform: translateY($y-pos-change);
    -ms-transform: translateY($y-pos-change);
    -o-transform: translateY($y-pos-change);
    transform: translateY($y-pos-change);
}
@mixin translate($x-pos, $y-pos, $x-pos-change, $y-pos-change) {
    top: $y-pos;
    left: $x-pos;
    -webkit-transform: translate($x-pos-change,$y-pos-change);
    -moz-transform: translate($x-pos-change,$y-pos-change);
    -ms-transform: translate($x-pos-change,$y-pos-change);
    -o-transform: translate($x-pos-change,$y-pos-change);
    transform: translate($x-pos-change,$y-pos-change);
}
@mixin rotate($rotation) {
    -webkit-transform: rotate($rotation);
    -moz-transform: rotate($rotation);
    -ms-transform: rotate($rotation);
    -o-transform: rotate($rotation);
    transform: rotate($rotation);
}
@mixin background-opacity($color, $opacity) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}
@mixin background-properties {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
@mixin cursor($cursor) {
    cursor: $cursor;  
    cursor: -webkit-$cursor;
}
@mixin posa-standard {
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
}