.group-summary-row {
    font-weight: 300;
    padding: 30px 0;
    .group-name{
        font-size:25px;
    }

    .group-summary-details {
        margin-bottom: 15px;
        p {
            display: inline-block;
            span {
                display: inline-block;
            }
            & + p {
                margin-left: 30px;
            }
        }
    }
    button + button {
        margin-top: 15px;
    }
    .group-summary-description {
        line-height: 22px;
        margin-bottom: 20px;
    }
    & + .group-summary-row {
        padding-top: 0;
        .col-md-10 {
            .group-summary-inner-wrapper {
                padding-top: 40px;
                border-top: 1px solid $site-white;
            }
        }
    }
}

// Media Queries
@media screen and ( min-width: $screen-sm ) {
    .group-summary-row {
        .group-summary-options {
            padding-top: 20px;
        }
    }
}
@media screen and ( min-width: $screen-md ) {
    .group-summary-row {
        button + button {
            margin-top: 30px;
        }
        .group-summary-description {
            margin-bottom: 0;
        }
    }
}
