.field-textinput {
    max-width: 280px;
    button {
        margin-top: 0;
    }
}
fieldset[class*="field-"] {
    display: inline-block;
    width: 100%;
    label {
        position: relative;
        font-size: 12px;
        margin: 0;
        font-weight: 400;
        max-width: 280px;
        width: 100%;
        text-transform: uppercase;
        span {
            width: auto;
            position: relative;
            z-index: 2;
            display: inline-block;
            padding: 10px 10px 10px 0;
            background-color: #fff;
        }
        hr {
            margin: 0;
            display: inline-block;
            position: absolute;
            z-index: 1;
            height: 1px;
            width: 100%;
            background-color: #fff;
            left: 0;
            @include translateY(50%,-50%);
        }
    }
    input, textarea {
        border: none;
        box-shadow: none;
        color: $body-bg;
        width: 100%;
        display: block;
        border: 1px solid #ddd;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    input {
        height: 45px;
        padding: 10px;
        border-radius: 2px;
        max-width: 280px;
        width: 100%;
    }
    textarea {
        border-radius: 10px;
        border-radius: 1px;
        padding: 10px;
    }
    & + fieldset {
        margin-top: 20px;
    }
    // @override Override for Profile Change screen
    .section-profile-change & {
        input {
            border: 1px solid #ddd;
        }
        label {
            span {
                color: $body-bg;
                background-color: #fff;
            }
            hr {
                background-color: $body-bg;
            }
        }
    }
}
form {
    .button-link[type="submit"] {
        margin-top: 30px;
    }
    .field-inputandbutton {
        display: block;
        max-width: 450px;
        input {
            display: inline-block;
        }
        .button-link {
            width: auto;
            padding: 15px 45px 12px;
            margin-top: 10px;
        }
    }
}
.select2-container--default {
    width: 100%!important;
    .select2-selection--single {
        background-color: #fff;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 2px;
        height: auto;
        color:#333;
        .select2-selection__arrow {
            top: 11px;
        }
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}
// @override Specific for Profile Change screen
.profile-update-block {
    border: 1px solid #ddd;
    padding: 30px 20px;
    border-radius: 2px;
    fieldset[class*="field-"] input, .field-textinput {
        max-width: 320px;
    }
    .select2-container--default {
        width: 100%!important;
        .select2-selection--single {
            background-color: #fff;
            border: 1px solid #ddd;
            padding: 10px;
            border-radius: 2px;
            height: auto;
            .select2-selection__arrow {
                top: 11px;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    &.bg-white {
        background-color: #fff;
    }
}

fieldset.centered-input{
    display: block;
    margin:0 auto;
    text-align: center;
	// border: 1px solid #ddd;
    border-radius: 3px;
    text-align: center;
    vertical-align: middle;
    padding: 16px 20px;
    font-size: 23px;
    margin-top: 16px;
    margin-bottom: 16px;

    input[type='tel']{
        height: none;
        max-width: none;
        padding: 10px 20px;
        height: 65px;
    }
}



.payment-form fieldset.field-textinput {
    // border: 1px solid #ddd;
    border-radius: 3px;
    margin: 10px;
}

.search {
    label {
        display: block;
        font-weight: 400;
        padding-left: 30px;
        position: relative;
        span:not(.display) {
            position: absolute;
            left: 0;
            top:0;
            width: 14px;
            height: 14px;
            text-align: center;
            &:before, &:after {
                content: "";
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                border: 1px solid #fff;
            }
            &:after {
                position: absolute;
                width: 0;
                height: 0;
                opacity: 0;
                border: none;
                display: inline-block;
                background-color: $site-yellow;
                @include transition(all,0.2s,ease,0s);
                @include translate(50%,50%,-50%,-50%);
            }
        }

        span.display{
            color: #afafaf;
        }
        p.display:hover{
            color:$body-bg;
        }

        &.radio-label {
            span {
                &:before,&:after {
                    border-radius: 100%;
                }
            }
        }
        &.checkbox-label {
            span {
                &:before{
                    border-radius: 2px;
                }
                &:after {
                    border-radius: 0;
                }
            }
        }
        & input:checked {
            & + span {
                &:after {
                    opacity: 1;
                    width: 7px;
                    height: 7px;
                    border: none;
                }
            }
        }
    }
    input[type="radio"],
    input[type="checkbox"] {
        display: none;
    }
}

// Media Queries
@media screen and ( min-width: $screen-sm ) {
    form {
        .field-inputandbutton {
            .button-link {
                margin-left: 15px;
                margin-top: 0;
            }
        }
    }
}
@media screen and ( min-width: $screen-md ) {
    // @override Avoid Profile Change screen
    section:not(.section-profile-change) {
        .field-textinput {
            & + .field-textinput:not(.field-inputandbutton) {
                margin-top: 0;
                margin-left: 36px;
            }
        }
    }
    .field-button {
        max-width: 212px;
    }
}
@media screen and ( min-width: $screen-lg ) {
    form {
        .field-button {
            max-width: 395px;
        }
    }
}
