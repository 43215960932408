.section-schedule-welcome-message {
    padding-bottom: 30px;
    .schedule-for {
        margin-top: 30px;
    }
}
.schedule-row {
    button, .button-link {
        display: inline-block;
        & + button, & + .button-link {
            margin-top: 10px;
        }
    }
    & + .schedule-row {
        margin-top: 35px;
    }
}
.previous-schedules-row, .schedule-summary {
    font-size: 24px;
    font-weight: 300;
}
.previous-schedules-row {
    margin-top: 40px;
}
.schedule-summary {
    padding: 30px 0;
    p {
        line-height: 28px; 
        & + p {
            margin-top: 30px;
        }
    }
    .exceeded-value {
        margin-top: 50px;
    }
}

// Media Queries
@media screen and ( min-width: $screen-md ) {
    .schedule-row {
        .prev-schedule-integer {
            padding-top: 15px;
        }
        button, .button-link {
            width: calc(50% - 20px);
            & + button, & + .button-link {
                margin-left: 30px;
                margin-top: 0;
            }
        }
    }
    .schedule-summary {
        padding: 80px 0 40px 0;
    }
}