.section-timeline {
    padding: 20px 0 40px;
    margin: 0 15px;
    overflow-x: auto;
    .col-xs-12 {
        padding: 0;
    }
    h2 {
        padding: 20px 0 30px;
    }
    .group-name {
        font-size: 18px;
        line-height: 24px;
    }
}
.timeline-wrapper {
    max-width: 950px;
    margin:0 auto;
    padding: 50px 0 10px;
    position: relative;
    .base-date {
        position: absolute;
        bottom: -12px;
        display: block;
        color: #65696C;
        font-size: 12px;
        &.start-date {
            left: 0;
        }
        &.end-date {
            right: 0;
        }
    }
    .timeline-marker {
        position: absolute;
        text-align: center;
        top: 0;
        height: 100%;
        max-width: 40px;
        z-index: 2;
        font-size: 16px;
        letter-spacing: normal;
        .marker {
            display: inline-block;
            position: absolute;
            top: 10px;
            padding: 5px 10px;
            border-radius: 4px;
            background-color: $site-red;
            @include translateX(50%,-50%);
            font-family: "Work Sans";
            &:before {
                content: "";
                position: absolute;
                bottom: -7px;
                width: 15px;
                height: 15px;
                display: inline-block;
                z-index: -1;
                background-color: $site-red;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
            }

            &.clickable{
                cursor: pointer;
            }

            &.paid{
                background-color: $site-green;

                &:before{
                    background-color: $site-green;
                }
            }

            &.active{
                // background-color: #dc63cd;
                background-color: $site-blue;

                &:before{
                    background-color: $site-blue;
                }
            }

			span{
				white-space: nowrap;
			}


            &:hover {
				z-index:10;

                & + .date {
                    opacity: 1;
                }
            }
        }
        .date {
            position: absolute;
            top: calc(100% + 10px);
            opacity: 0.3;
            @include transition(all,0.3s,ease,0s);
            @include translateX(50%,-50%);
        }
    }
}
.progress-row {
    & + .progress-row {
        margin-top: 60px;
    }
}
.progress {
    margin: 0;
    .progress-bar {
        border-radius: 4px;
    }
}

.payment-amount{
	margin-top: 60px;
	line-height: 33px;
}


// Media Queries
@media screen and ( min-width: $screen-sm ) {
    .section-timeline {
        margin: 0;
        overflow-x: hidden;
        .col-xs-12 {
            padding: 0 21px;
        }
    }
    .timeline-wrapper {
        width: auto;
    }
}
@media screen and ( min-width: 1400px ) {
    .timeline-wrapper {
        .base-date {
            bottom: 12px;
            &.start-date {
                left: -9%;
            }
            &.end-date {
                right: -9%;
            }
        }
    }
}
