$site-pink : #b006ef;
$site-yellow : #fcea68;
$site-red : #dc6763;
$site-purple : #89729b;
$site-white : #f2f2f3;
$site-grey : #b9b7bd;
$site-green : #0eebc1;
$site-blue : #44b2ff;

$business-warn:lighten($site-red, 20%);
$business-blue: #73a3ec;
$business-green: #c0bc86;
$business-white: #f8f8f9;
$business-black: #383838;

// Body
$body-bg: #24292e;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$line-height-base: 1.6;

// Navbar
$navbar-default-bg: #fff;

// Panels
$panel-default-heading-bg: #fff;
